import React from "react"
import SectionTitle from "../components/SectionTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PageWrapper from "../components/layout/PageWrapper"
import PrivacyPolicy from "../components/content/PrivacyPolicy"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO
      title="Gas fireplace and wood burning stoves installation"
      description="Gas, Solid Fuel And Bio Fuel Fireplaces Design, Installation and Maintenance"
    />
    <PageWrapper>
      <SectionTitle title="Privacy Policy" strapline=" " />
      <PrivacyPolicy />
    </PageWrapper>
  </Layout>
)

export default PrivacyPolicyPage
