import React from "react"
import styled from "styled-components"

const SectionTitle = ({ title, strapline }) => {
  return (
    <Wrapper>
      <h1>{title || "fire skilled"}</h1>
      <h2>{strapline || "gas fireplace installers"}</h2>
    </Wrapper>
  )
}

export default SectionTitle

const Wrapper = styled.header`
  margin-top: 1.25rem;
  margin-bottom: 5.25rem;
  position: relative;
  &:before {
    // content: "";
    position: absolute;
    bottom: -1.25rem;
    left: 50%;
    transform: translate(-50%);
    height: 2px;
    width: 50px;
    background: var(--color-motif-1);
  }
  h1 {
    font-size: 2.5rem;
    color: var(--color-primary-1);
    font-weight: 400;
    text-align: center;
  }
  h2 {
    font-size: 1.25rem;
    color: var(--color-primary-2);
    font-weight: 400;
    text-align: center;
    text-transform: none;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem 1rem 3rem 1rem;
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1rem;
    }
  }
`
