import styled from "styled-components"

const PageWrapper = styled.main`
  background: var(--bg-1);
  min-height: calc(100vh - 5rem - 9rem);
  padding: 3rem 0;
  @media screen and (max-width: 768px) {
    padding: 1.5rem 0;
  }
`

export default PageWrapper
